// Global Styles
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #333;
  background: #f9f9f9;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

// Header
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 3rem;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  z-index: 100;

  .logo img {
    height: 60px;
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;

    &:focus {
      outline: none;
    }
  }

  .nav-links {
    display: flex;

    a {
      margin-left: 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      color: #333;
      transition: color 0.3s;

      &:hover {
        color: #28a745;
      }
    }
  }
}

// Hero Section
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  background: linear-gradient(135deg, #28a745, #218838);
  color: #fff;
  position: relative;

  .hero-content {
    max-width: 600px;

    .hero-title {
      font-size: 3.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
      animation: fadeInDown 1s;

      span {
        color: #fff300;
        text-shadow: 0 0 10px rgba(255, 243, 0, 0.8);
      }
    }

    .hero-description {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      animation: fadeInUp 1.5s;
    }

    .cta-buttons {
      display: flex;
      gap: 1rem;

      .google-play-logo,
      .app-store-logo {
        width: auto;
        height: 70px;
        cursor: pointer;
        border-radius: 12px;
      }
    }
  }

  .hero-animation {
    position: relative;

    img {
      position: relative;
      z-index: 10;
      animation: zoomIn 4s;
      background: white;
      border-radius: 50%;
    }
  }
}

// Features Section
.features {
  padding: 0rem 2rem;
  text-align: center;

  h2 {
    font-size: 2rem;
    color: #28a745;
    margin-bottom: 1rem;
    animation: fadeIn 3s;
  }

  .feature-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;

    .feature-card {
      background: #fff;
      padding: 1.5rem;
      border-radius: 12px;
      width: 250px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }

      h3 {
        font-size: 1.3rem;
        color: #28a745;
        margin-bottom: 0.5rem;
      }

      p {
        color: #555;
      }
    }
  }
}

// Why Choose Fitoja Section
.why-choose {
  padding: 1rem 2rem;
  background: #f3f3f3;
  text-align: center;

  h2 {
    font-size: 2rem;
    color: #28a745;
    margin-bottom: 1rem;
    animation: fadeIn 1s;
  }

  p {
    font-size: 1rem;
    color: #555;
    margin: 1rem auto;
    max-width: 800px;
    line-height: 1.6;
  }
}

// Footer
.footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  font-size: 0.9rem;
}

// Animations
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes blobMove {
  0%,
  100% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-20px) rotate(30deg);
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0.3rem 0.4rem;
    .menu-toggle {
      display: block;
    }
    .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: 1rem 0;

      a {
        margin: 0.5rem 0;
        text-align: center;
      }

      &.open {
        display: flex;
      }
    }
  }
  .logo img {
    height: 50px;
  }

  .nav-links {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-links a {
    margin-left: 0;
    margin-top: 0.5rem;
    font-size: 1.1rem;
  }

  .hero {
    flex-direction: column;
    padding: 0 1rem 2rem 1rem;
  }
  .hero-title {
    font-size: 2.5rem;
  }
  .hero .hero-content .hero-title {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .hero .hero-content .cta-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .hero .hero-content .cta-buttons .google-play-logo,
  .hero .hero-content .cta-buttons .app-store-logo {
    width: 10rem;
    height: 3rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .cta-button {
    text-align: center;
  }

  .hero-animation {
    display: none;
  }

  .features {
    padding: 0rem 1rem;
  }

  .feature-cards {
    flex-direction: column;
    gap: 1rem;
  }

  .feature-card {
    width: 90%;
    max-width: 300px;
  }

  .features .feature-cards .feature-card {
    width: auto;
  }

  .why-choose {
    padding: 2rem 1rem;
  }

  .footer {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }

  .feature-card {
    width: 100%;
  }
}
